export const imports = {
  'src/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-index" */ 'src/index.mdx'
    ),
  'src/components/Header/Header.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-header-header" */ 'src/components/Header/Header.mdx'
    ),
  'src/components/HeaderControls/HeaderControls.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-header-controls-header-controls" */ 'src/components/HeaderControls/HeaderControls.mdx'
    ),
  'src/components/Icons/VisualizerIcon.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-icons-visualizer-icon" */ 'src/components/Icons/VisualizerIcon.mdx'
    ),
  'src/components/Loaders/LinearLoader.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-loaders-linear-loader" */ 'src/components/Loaders/LinearLoader.mdx'
    ),
  'src/components/NavigationBar/NavigationBar.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-navigation-bar-navigation-bar" */ 'src/components/NavigationBar/NavigationBar.mdx'
    ),
  'src/components/Visualizers/Visualizers.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-visualizers-visualizers" */ 'src/components/Visualizers/Visualizers.mdx'
    ),
  'src/containers/AboutPage/AboutPage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-containers-about-page-about-page" */ 'src/containers/AboutPage/AboutPage.mdx'
    ),
  'src/containers/ApplicationPage/ApplicationPage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-containers-application-page-application-page" */ 'src/containers/ApplicationPage/ApplicationPage.mdx'
    ),
  'src/containers/AuthorizationPage/AuthorizationPage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-containers-authorization-page-authorization-page" */ 'src/containers/AuthorizationPage/AuthorizationPage.mdx'
    ),
  'src/containers/CreateVisualizerPage/CreateVIsualizerPage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-containers-create-visualizer-page-create-v-isualizer-page" */ 'src/containers/CreateVisualizerPage/CreateVIsualizerPage.mdx'
    ),
  'src/containers/DiscoverPage/DiscoverPage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-containers-discover-page-discover-page" */ 'src/containers/DiscoverPage/DiscoverPage.mdx'
    ),
  'src/containers/HomePage/HomePage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-containers-home-page-home-page" */ 'src/containers/HomePage/HomePage.mdx'
    ),
  'src/containers/NotFoundPage/NotFoundPage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-containers-not-found-page-not-found-page" */ 'src/containers/NotFoundPage/NotFoundPage.mdx'
    ),
  'src/containers/SettingsPage/Profile/UserProfilePage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-containers-settings-page-profile-user-profile-page" */ 'src/containers/SettingsPage/Profile/UserProfilePage.mdx'
    ),
  'src/containers/SettingsPage/Settings/SettingsPage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-containers-settings-page-settings-settings-page" */ 'src/containers/SettingsPage/Settings/SettingsPage.mdx'
    ),
}
