const SET_APPLICATION = 'SET_APPLICATION';
const RESET_APPLICATION = 'RESET_APPLICATION';
const SET_APPLICATION_TITLE = 'SET_APPLICATION_TITLE';
const SET_APPLICATION_METADATA = 'SET_APPLICATION_METADATA';
const RESET_APPLICATION_METADATA = 'RESET_APPLICATION_METADATA';

export default {
  SET_APPLICATION,
  RESET_APPLICATION,
  SET_APPLICATION_TITLE,
  SET_APPLICATION_METADATA,
  RESET_APPLICATION_METADATA
};
