const SET_SELECTED_VISUALIZER = 'SET_SELECTED_VISUALIZER';
const SET_CHOOSE_FOLDER_DIALOG_STATE = 'SET_CHOOSE_FOLDER_DIALOG_STATE';
const SET_INBOX_DIALOG_STATE = 'SET_INBOX_DIALOG_STATE';
const SET_ACCESS_CONTROL_DIALOG_STATE = 'SET_ACCESS_CONTROL_DIALOG_STATE';
const SET_SELECTED_DASHBOARD_TAB_INDEX = 'SET_SELECTED_DASHBOARD_TAB_INDEX';
const SET_MOBILE_DRAWER_STATE = 'SET_MOBILE_DRAWER_STATE';
const SET_SELECTED_DISCOVER_TAB_INDEX = 'SET_SELECTED_DISCOVER_TAB_INDEX';
const SET_SELECTED_APPLICATION_SETUP_TAB_INDEX =
  'SET_SELECTED_APPLICATION_SETUP_TAB_INDEX';
const SET_SELECTED_APPLICATIONS_BROWSER_TAB_INDEX =
  'SET_SELECTED_APPLICATIONS_BROWSER_TAB_INDEX';
const SET_SELECTED_SETTINGS_TAB_INDEX = 'SET_SELECTED_SETTINGS_TAB_INDEX';
const SET_SELECTED_NAVIGATION_ITEM = 'SET_SELECTED_NAVIGATION_ITEM';

export default {
  SET_SELECTED_VISUALIZER,
  SET_CHOOSE_FOLDER_DIALOG_STATE,
  SET_SELECTED_DASHBOARD_TAB_INDEX,
  SET_INBOX_DIALOG_STATE,
  SET_ACCESS_CONTROL_DIALOG_STATE,
  SET_MOBILE_DRAWER_STATE,
  SET_SELECTED_DISCOVER_TAB_INDEX,
  SET_SELECTED_APPLICATION_SETUP_TAB_INDEX,
  SET_SELECTED_APPLICATIONS_BROWSER_TAB_INDEX,
  SET_SELECTED_SETTINGS_TAB_INDEX,
  SET_SELECTED_NAVIGATION_ITEM
};
