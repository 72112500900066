const SET_SELECTED_SCHEME = 'SET_SELECTED_SCHEME';
const SET_SELECTED_NODES = 'SET_SELECTED_NODES';
const SET_DEFAULT_FILTERS_STATE = 'SET_DEFAULT_FILTERS_STATE';
const TOGGLE_ENABLED = 'TOGGLE_ENABLED';
const TOGGLE_VISIBLE = 'TOGGLE_VISIBLE';
const RESET_FILTERS = 'RESET_FILTERS';
const SET_FILTERS_STATE = 'SET_FILTERS_STATE';
const SET_SELECTED_MAP_OPTIONS = 'SET_SELECTED_MAP_OPTIONS';

export default {
  SET_SELECTED_SCHEME,
  SET_SELECTED_NODES,
  SET_DEFAULT_FILTERS_STATE,
  SET_FILTERS_STATE,
  TOGGLE_ENABLED,
  TOGGLE_VISIBLE,
  RESET_FILTERS,
  SET_SELECTED_MAP_OPTIONS
};
