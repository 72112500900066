const REMOVE_EXECUTION = 'REMOVE_EXECUTION';
const ADD_EXPORT = 'ADD_EXPORT';
const REMOVE_EXPORT = 'REMOVE_EXPORT';
const SET_SELECTED_RESULT_GRAPH_IRI = 'SET_SELECTED_RESULT_GRAPH_IRI';
const SET_PIPELINE_ID = 'SET_PIPELINE_ID';
const SET_PIPELINE_EXECUTION = 'SET_PIPELINE_EXECUTION';

export default {
  REMOVE_EXECUTION,
  ADD_EXPORT,
  REMOVE_EXPORT,
  SET_SELECTED_RESULT_GRAPH_IRI,
  SET_PIPELINE_ID,
  SET_PIPELINE_EXECUTION
};
